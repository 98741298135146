import axios from 'axios'
import router from '@/router/router'
import store from '@/store/store'
import { postSessionRefreshApi } from './auth.service'
import { URLS } from '@/config/urls.config'
import { sleep } from '@/utils/utilities.util'

const api = axios.create({
  baseUlr: '/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Authorization',
  },
})

const requestSuccess = reponse => {
  return reponse
}

const responseError = async error => {
  const originalRequest = error.config
  if (error.response.status === 498) {
    await postSessionRefreshApi()
    return api(originalRequest)
  } else if (
    error.response.status === 401 &&
    (error.response.data.message === 'AUTHENTICATION_FAILED' || error.response.data.message === 'INVALID_REFRESH_TOKEN')
  ) {
    await store.dispatch('backoffice/userLogout')
    window.location.assign(
      `${URLS.SSO}/?app_version=3&redirect=${encodeURIComponent(location.pathname + location.search)}&sign_out=1`
    )
    await sleep(2000)
  } else if (error.response.status === 401) {
    router.push({ name: 'Error', params: { 0: router.currentRoute.path } })
  }
  return Promise.reject(error)
}

api.interceptors.response.use(requestSuccess, responseError)

export const sendGetRequest = async (url, config = null) => {
  try {
    const { data } = await api.get(url, config)
    return data
  } catch ({ response }) {
    throw response
  }
}

export const sendGetRequestWithFullResponse = async (url, config = null) => {
  try {
    const result = await api.get(url, config)
    return result
  } catch ({ response }) {
    throw response
  }
}

export const sendPutRequest = async (url, content, config = null) => {
  try {
    const { data } = await api.put(url, content, config)
    return data
  } catch ({ response }) {
    throw response
  }
}

export const sendPostRequest = async (url, content, config = null) => {
  try {
    const { data } = await api.post(url, content, config)
    return data
  } catch ({ response }) {
    throw response
  }
}

export const sendPatchRequest = async (
  url,
  content,
  config = {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  }
) => {
  try {
    const { data } = await api.patch(url, content, config)
    return data
  } catch ({ response }) {
    throw response
  }
}

export const sendDeleteRequest = async (url, config = null) => {
  try {
    const { data } = await api.delete(url, config)
    return data
  } catch ({ response }) {
    throw response
  }
}
