// Redirect legacy route and manage in app clientId
export const oldRoutesRedirect = [
  {
    path: '/touchpoints/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/profilehub/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/communication/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/platform/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/settings/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/public/lead/*',
    redirect: to => {
      return {
        path: `/protected/lead/${to.params.pathMatch}`,
      }
    },
  },
  {
    path: '/profile',
    redirect: '/clients/id/profile',
  },
]
