import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_AUTO_REPLY } from '@/config/api.config'

//Semantic
export const getBrandUuidApi = brandId => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.BRAND_UUID}?brandId=${brandId}`)
}

//Settings
export const getSettingsApi = brandId => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.SETTINGS}?brandId=${brandId}`)
}

export const postSettingsApi = (brandId, data) => {
  return sendPostRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.SETTINGS}?brandId=${brandId}`, data)
}

//Reviews
export const getReviewsApi = (brandId, urlParams, filterParams) => {
  return sendGetRequest(
    `${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.REVIEWS_LIST}?brandId=${brandId}${urlParams}${filterParams}`
  )
}

export const getReviewIdApi = (brandId, id) => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.REVIEW_ID}?brandId=${brandId}&id=${id}`)
}

export const postReviewIdApi = (brandId, id, data) => {
  return sendPostRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.REVIEW_ID}?brandId=${brandId}&id=${id}`, data)
}

export const postSendReplyApi = (brandId, id, data) => {
  return sendPostRequest(
    `${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.SEND_RESPONSE}?brandId=${brandId}&id=${id}`,
    data
  )
}

export const postSaveReplyApi = (brandId, id, data) => {
  return sendPostRequest(
    `${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.SEND_RESPONSE}?brandId=${brandId}&id=${id}&save=1`,
    data
  )
}

export const postSendAllReplyApi = (brandId, data, filterParams) => {
  return sendPostRequest(
    `${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.SEND_ALL_RESPONSES}?brandId=${brandId}${filterParams}`,
    data
  )
}

export const getReplyApi = (brandId, id) => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.BOT_EVALUATION}?brandId=${brandId}&id=${id}`)
}

export const getExportApi = (brandId, urlParams, filterParams) => {
  return sendGetRequest(
    `${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.REVIEWS_LIST}?brandId=${brandId}${urlParams}${filterParams}`,
    {
      responseType: 'blob',
    }
  )
}

export const getFiltersApi = brandId => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.AVAILABLE_FILTERS}?brandId=${brandId}`)
}

export const postReportResponseApi = (brandId, data) => {
  return sendPostRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.REPORT_RESPONSE}?brandId=${brandId}`, data)
}

export const getDeactivateReviewApi = (brandId, id) => {
  return sendGetRequest(`${API_AUTO_REPLY.BASE_API_URL}${API_AUTO_REPLY.DEACTIVATE_REVIEW}?brandId=${brandId}&id=${id}`)
}
