import Vue from 'vue'
import qs from 'qs'
import VueRouter from 'vue-router'
import store from '@/store/store'
import { URLS } from '@/config/urls.config'
import { getUserToken, isLogged, isAuthorized, isNavigationAccess } from '@/utils/auth.util'

import { oldRoutesRedirect } from './oldRoutesRedirection'

Vue.use(VueRouter)

const routes = [
  ...oldRoutesRedirect,
  {
    path: '/',
    redirect: {
      name: 'Client',
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:clientId',
    name: 'Client',
    component: () => import('@/views/Backoffice.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':module(profilehub)/dashboard',
        name: 'ProfileHubDashboard',
        component: () => import('@/views/ProfileHub/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(profilehub)/import',
        name: 'ProfileHubImport',
        component: () => import('@/views/ProfileHub/Import.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(profilehub)/export',
        name: 'ProfileHubExport',
        component: () => import('@/views/ProfileHub/Export.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(profilehub)/contacts',
        component: () => import('@/views/ProfileHub/Contacts.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'ProfileHubContactsList',
            component: () => import('@/views/ProfileHub/Contacts/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id([0-9a-fA-F]*)',
            name: 'ProfileHubContactsIndividual',
            component: () => import('@/views/ProfileHub/Contacts/Individual.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: ':module(profilehub)/criteria',
        component: () => import('@/views/ProfileHub/Criteria.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'ProfileHubCriteriaList',
            component: () => import('@/views/ProfileHub/Criteria/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ':id([0-9a-fA-F]*)',
            name: 'ProfileHubCriteriaIndividual',
            component: () => import('@/views/ProfileHub/Criteria/Individual.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'create',
            name: 'ProfileHubCriteriaIndividualCreate',
            component: () => import('@/views/ProfileHub/Criteria/Individual.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: ':module(profilehub)/criteria-mapping',
        name: 'ProfileHubCriteriaMapping',
        component: () => import('@/views/ProfileHub/Criteria/Mapping.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(communication)/semantic-analysis',
        name: 'ReviewRepliesSemantic',
        component: () => import('@/views/AutoReply/SemanticAnalysis.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(communication)/settings',
        name: 'AutoReplySettings',
        component: () => import('@/views/AutoReply/Settings.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(communication)/reviews',
        name: 'AutoReplyReviews',
        component: () => import('@/views/AutoReply/Reviews.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(touchpoints)/list',
        name: 'TouchpointsList',
        component: () => import('@/views/Touchpoints/List.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(touchpoints)/creation',
        name: 'TouchpointsCreation',
        component: () => import('@/views/Touchpoints/Creation.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(touchpoints)/import',
        name: 'TouchpointsImport',
        component: () => import('@/views/Touchpoints/Import.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(touchpoints)/:id([0-9a-fA-F]{24})',
        name: 'TouchpointsEdition',
        component: () => import('@/views/Touchpoints/Edition.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(platform)/leads',
        name: 'PlatformLeads',
        component: () => import('@/views/Platform/Leads.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(platform)/forms',
        name: 'PlatformForm',
        component: () => import('@/views/Platform/Forms.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(platform)/conversion',
        name: 'PlatformConversion',
        component: () => import('@/views/Platform/Conversion.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(platform)/opportunity',
        name: 'PlatformOpportunity',
        component: () => import('@/features/opportunity/views/Opportunity.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(platform)/configuration',
        name: 'PlatformOpportunityConfiguration',
        component: () => import('@/views/Opportunity/Configuration.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(settings)/list',
        component: () => import('@/views/User.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'UserList',
            component: () => import('@/views/User/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'manage',
            name: 'UserManage',
            component: () => import('@/views/User/Manage.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'manage/:id',
            name: 'UserEdit',
            component: () => import('@/views/User/Manage.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/protected',
    component: () => import('@/views/Protected.vue'),
    children: [
      {
        path: 'lead/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        name: 'ProtectedLead',
        component: () => import('@/views/Protected/Lead.vue'),
      },
      { path: 'expired', name: 'ProtectedLeadExpired', component: () => import('@/views/Protected/Lead/Expired.vue') },
      { path: 'not-found', name: 'ProtectedLead404', component: () => import('@/views/Protected/Lead/404.vue') },
    ],
  },
  {
    path: '/public/leads/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:action',
    name: 'LeadAction',
    component: () => import('@/views/Public/LeadAction.vue'),
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/Report.vue'),
  },
  { path: '/no-access', name: 'NoAccess', component: () => import('@/views/NoAccess.vue') },
  { path: '/verify-auth', name: 'VerifyAuth', component: () => import('@/views/VerifyAuth.vue') },
  { path: '*', name: 'Error', component: () => import('@/views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query)
  },
  stringifyQuery(query) {
    var result = qs.stringify(query, { encode: false })

    return result ? '?' + result : ''
  },
})

router.beforeEach(async (to, from, next) => {
  const appState = store.state.backoffice

  isLogged(to, next, appState)

  if (isAuthorized(to, appState, getUserToken())) {
    return next()
  }

  if (getUserToken() !== undefined) {
    if (appState.currentUser && !isNavigationAccess(to.meta.roles, appState.currentUser)) {
      return next({ name: 'Error' })
    }
    return next()
  }

  await store.dispatch('backoffice/userLogout')
  return window.location.assign(`${URLS.SSO}/?app_version=3&redirect=${encodeURIComponent(to.fullPath)}&sign_out=1`)
})

export default router
