import { sendGetRequest, sendPostRequest, sendPatchRequest } from '@/services/api.service'
import { GTR_CORE, API_SA } from '@/config/api.config'

export const getUsersApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}?${urlParams}`)
}

export const getUserApi = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}${urlParams}`)
}

export const postUserApi = body => {
  return sendPostRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}`, body)
}

export const patchUserApi = (urlParams, body) => {
  return sendPatchRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USERS}${urlParams}`, body)
}

export const getUserMySelfsApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.USER}${GTR_CORE.MY_SELF}`)
}

export const getRolesApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.ROLES}`)
}

export const getUserAssociationApi = urlParams => {
  return sendGetRequest(`${API_SA.BASE_API_URL}${API_SA.USER_ASSOCIATION}?${urlParams}`)
}

export const getUserMenusApi = role => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.MENUS}?exists[parentMenu]=false&roles=${role}`)
}
