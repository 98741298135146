export const defaultLangList = [
  {
    locale: 'fr',
    active: true,
  },
  {
    locale: 'en',
    active: true,
  },
  {
    locale: 'es',
    active: true,
  },
  {
    locale: 'it',
    active: true,
  },
  {
    locale: 'de',
    active: true,
  },
  {
    locale: 'no',
    active: true,
  },
]
