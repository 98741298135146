import i18n from '@/i18n/i18n'

// Return true if array, object, or string is empty
export const isEmpty = value => {
  if (typeof value === 'number') {
    return false
  }

  if (!value) {
    return true
  }

  if (Array.isArray(value)) {
    return !value.length || !value[0]
  }

  for (const key in value) {
    if (hasOwnProperty.call(value, key)) {
      return false
    }
  }

  return true
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value != null)
      .map(([key, value]) => [key, value === Object(value) ? removeEmpty(value) : value])
  )
}

export const convertToUnit = (str, unit = 'px') => {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return String(str)
  } else {
    return `${Number(str)}${unit}`
  }
}

export const numberFormat = ({
  locale = i18n.locale,
  number,
  style = 'decimal',
  currency = 'EUR',
  display = 'short',
  digit = 1,
  notation = 'compact',
  additionalOptions = {},
}) => {
  return new Intl.NumberFormat(locale, {
    style,
    currency,
    notation,
    minimumFractionDigits: 0,
    maximumFractionDigits: digit,
    compactDisplay: display,
    ...additionalOptions,
  }).format(number)
}

export const formattedPrice = ({ number, currency, digit }) => {
  const centsToCurrency = number / 100
  const options = digit ? { digit } : { additionalOptions: { maximumSignificantDigits: 3 } }

  return numberFormat({
    number: centsToCurrency,
    style: 'currency',
    currency: currency ?? 'USD',
    ...options,
  })
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Convert object to array if needed.
 * @param item
 * @returns {boolean}
 */
export const toArray = item => {
  return Array.isArray(item) ? item : [item]
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const getPropertyByLocale = (object = {}, locale = 'en') => {
  return Object.fromEntries(
    Object.entries(object)
      .map(([key, value]) => [key, value[locale] || value['en']])
      .filter(([key, value]) => key && value)
  )
}
