import i18n from '@/i18n/i18n'

export const highlightText = (text, search) => {
  if (search && text) {
    const searchRegExp = new RegExp(search, 'gi')
    return text.toString().replaceAll(searchRegExp, '<mark>$&</mark>')
  }
  return text
}

export const decimalSeparator = { fr: ',', en: '.' }[i18n.locale] || '.'

export const getShortLocal = locale => locale.substring(0, 2)

export const capitalize = str => {
  if (str?.charAt(0)) return str.charAt(0).toUpperCase() + str.slice(1)
}
