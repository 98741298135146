import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import minifyTheme from 'minify-css-string'
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr, en },
    current: 'fr',
  },
  theme: {
    dark: !!(localStorage.getItem('dark_theme') * 1) || false,
    themes: {
      light: {
        primary: '#449afd',
        secondary: '#6b7280',
        navigation: '#0f172a',
      },
      dark: {
        primary: '#449afd',
        secondary: '#fff',
        navigation: '#0f172a',
      },
    },
    options: {
      customProperties: true,
      minifyTheme,
    },
  },
  breakpoint: {
    thresholds: {
      xs: 768,
      sm: 1024,
      md: 1280,
      lg: 1920,
    },
    scrollBarWidth: 0,
    mobileBreakpoint: 'sm',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
})
