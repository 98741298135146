import { formatedDate } from '@/utils/date.util'
import { downloadFile } from '@/utils/utilities.util'
import { tableOptions } from '@/utils/table.util'
import {
  getDashboardApi,
  postImportApi,
  getCriteriaGtrApi,
  getCriteriaCustomApi,
  postNewCriteriaApi,
  patchCriteriaApi,
  postTemplateApi,
  postProfilesApi,
  getSegmentsApi,
  putSegmentApi,
  postSegmentApi,
  deleteSegmentApi,
  postOptinApi,
  postExportApi,
  getFileApi,
  getProfileApi,
  getBrandCriteriaApi,
  getIdCriteriaApi,
  getCampaignApi,
  postCampaignApi,
  getMappingApi,
  postMappingApi,
  getMappingQuestionApi,
  postMappingQuestionApi,
  getMappingQuestionAnswerApi,
  postMappingAnswerApi,
  getMappingCriteriaApi,
  getAvailableQA,
  deleteMappingQuestionApi,
} from '@/services/profilehub.service'

const SET_DASHBOARD = 'SET_DASHBOARD'
const SET_CRITERIA = 'SET_CRITERIA'
const SET_BRAND_CRITERIA = 'SET_BRAND_CRITERIA'
const SET_ID_CRITERIA = 'SET_ID_CRITERIA'
const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
const SET_MAPPING_OPTIONS = 'SET_MAPPING_OPTIONS'
const SET_CURRENT_MAPPING = 'SET_CURRENT_MAPPING'
const SET_CURRENT_MAPPING_QUESTION = 'SET_CURRENT_MAPPING_QUESTION'
const SET_CURRENT_MAPPING_QUESTION_ANSWER = 'SET_CURRENT_MAPPING_QUESTION_ANSWER'
const SET_CURRENT_QA = 'SET_CURRENT_QA'
const SET_NEW_QUESTIONS = 'SET_NEW_QUESTIONS'
const SET_MAPPING_CRITERIA = 'SET_MAPPING_CRITERIA'
const SET_PROFILES = 'SET_PROFILES'
const SET_PROFILES_PAGINATION = 'SET_PROFILES_PAGINATION'
const SET_SEGMENTS = 'SET_SEGMENTS'
const SET_CURRENT_SEGMENT = 'SET_CURRENT_SEGMENT'
const ADD_SEGMENT = 'ADD_SEGMENT'
const UPDATE_PROFILES = 'UPDATE_PROFILES'
const SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    dashboard: null,
    criteria: [],
    brandCriteria: null,
    mappingCriteria: null,
    idCriteria: null,
    profiles: [],
    profilesPagination: {
      page: 1,
      itemsPerPage: 100,
      total: 0,
    },
    segments: [],
    currentSegment: {
      rules: {
        condition: 'and',
        filters: [],
      },
    },
    campaigns: null,
    currentProfile: null,
    mappingOptions: null,
    currentMapping: [],
    currentMappingQuestions: [],
    currentMappingQuestionAnswers: [],
    currentQA: [],
    newQuestions: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_DASHBOARD]: (state, data) => {
    state.dashboard = data
  },
  [SET_CRITERIA]: (state, criteria) => {
    state.criteria = criteria
  },
  [SET_BRAND_CRITERIA]: (state, brandCriteria) => {
    state.brandCriteria = brandCriteria
  },
  [SET_ID_CRITERIA]: (state, idCriteria) => {
    state.idCriteria = idCriteria
  },
  [SET_CAMPAIGNS]: (state, campaigns) => {
    state.campaigns = campaigns
  },
  [SET_MAPPING_OPTIONS]: (state, mappingOptions) => {
    state.mappingOptions = mappingOptions
  },
  [SET_CURRENT_MAPPING]: (state, currentMapping) => {
    state.currentMapping = currentMapping
  },
  [SET_CURRENT_MAPPING_QUESTION]: (state, currentMappingQuestions) => {
    state.currentMappingQuestions = currentMappingQuestions
  },
  [SET_CURRENT_MAPPING_QUESTION_ANSWER]: (state, currentMappingQuestionAnswers) => {
    state.currentMappingQuestionAnswers.push(currentMappingQuestionAnswers)
  },
  [SET_CURRENT_QA]: (state, currentQA) => {
    state.currentQA = currentQA
  },
  [SET_NEW_QUESTIONS]: (state, newQuestions) => {
    state.newQuestions.push(newQuestions)
  },
  [SET_MAPPING_CRITERIA]: (state, mappingCriteria) => {
    state.mappingCriteria = mappingCriteria
  },
  [SET_PROFILES]: (state, profiles) => {
    state.profiles = profiles
  },
  [SET_PROFILES_PAGINATION]: (state, pagination) => {
    state.profilesPagination = {
      ...state.profilesPagination,
      ...pagination,
    }
  },
  [SET_SEGMENTS]: (state, segments) => {
    state.segments = segments
  },
  [SET_CURRENT_SEGMENT]: (state, segment) => {
    if (segment) {
      state.currentSegment = segment
    } else {
      state.currentSegment = getDefaultState().currentSegment
    }
  },
  [ADD_SEGMENT]: (state, segment) => {
    state.segments.push(segment)
  },

  [UPDATE_PROFILES]: (state, { item, optinValue }) => {
    state.profiles = state.profiles.map(p => {
      if (p.id === item.id) {
        return {
          ...p,
          criterias: {
            ...p.criterias,
            optin: optinValue,
          },
        }
      }
      return p
    })
  },
  [SET_CURRENT_PROFILE]: (state, currentProfile) => {
    state.currentProfile = currentProfile
  },

  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  //Dashboard
  async getDashboard({ commit, rootState }) {
    const { data } = await getDashboardApi(rootState.backoffice.currentClient.brandId)
    commit(SET_DASHBOARD, data)
  },
  //Import
  async sendFile({ rootState }, formData) {
    await postImportApi(rootState.backoffice.currentClient.brandId, formData)
  },
  async getTemplate(_, data) {
    const file = await postTemplateApi(data)
    const fileName = data.filename || `import_${formatedDate('now', 'DDMMYYYY')}`
    const fileExtension = data.extension || 'csv'
    downloadFile(file, `${fileName}.${fileExtension}`)
  },
  //Criteria
  async getCriteria({ commit, rootState }) {
    let [{ data: criteriaGtr }, { data: criteriaCustom }] = await Promise.all([
      getCriteriaGtrApi(),
      getCriteriaCustomApi(rootState.backoffice.currentClient.brandId),
    ])
    criteriaGtr = criteriaGtr.map(c => ({
      ...c,
      required: !!c.required,
    }))
    criteriaCustom = criteriaCustom.map(c => ({
      ...c,
      required: !!c.required,
    }))
    commit(SET_CRITERIA, criteriaGtr.concat(criteriaCustom))
  },
  async getBrandCriteria({ commit, rootState }) {
    const { data } = await getBrandCriteriaApi(rootState.backoffice.currentClient.brandId)
    commit(SET_BRAND_CRITERIA, data)
  },
  async getIdCriteria({ commit }, id) {
    const { data } = await getIdCriteriaApi(id)
    commit(SET_ID_CRITERIA, data[0])
  },
  async postNewCriteria({ rootState }, newCriteria) {
    await postNewCriteriaApi(rootState.backoffice.currentClient.brandId, newCriteria)
  },
  async patchCriteria(_, { id, updatedCriteria }) {
    await patchCriteriaApi(id, updatedCriteria)
  },
  async getQA({ commit }, id) {
    const { data } = await getAvailableQA(id)
    commit(SET_CURRENT_QA, data)
  },

  //Campaigns
  async getCampaign({ commit, rootState }, { type, origin }) {
    const { data } = await getCampaignApi(rootState.backoffice.currentClient.brandId, type, origin)
    const mappingOptions = {
      brand_id: rootState.backoffice.currentClient.brandId,
      type: type,
      origin: origin,
    }
    commit(SET_CAMPAIGNS, data)
    commit(SET_MAPPING_OPTIONS, mappingOptions)
  },

  async postCampaign(_, { id, body }) {
    await postCampaignApi(id, body)
  },

  async getMapping({ commit, state }, id) {
    const { data } = await getMappingApi(state.mappingOptions, id)
    commit(SET_CURRENT_MAPPING, data)
  },

  async getNewMapping({ state }, { name, feedback_template_id }) {
    const data = await postMappingApi(state.mappingOptions, name, feedback_template_id)
    return data.data
  },

  async getMappingQuestion({ commit }, id) {
    const { data } = await getMappingQuestionApi(id)
    commit(SET_CURRENT_MAPPING_QUESTION, data)
  },

  async deleteMappingQuestion({ commit }, { id, gtr_mapping_transfer_id }) {
    const { data } = await deleteMappingQuestionApi(id, gtr_mapping_transfer_id)
    commit(SET_CURRENT_MAPPING_QUESTION, data)
  },

  async postMappingQuestion({ commit }, body) {
    const { data } = await postMappingQuestionApi(body)
    const newQuestion = {
      id: body.id,
      gtr_mapping_transfer_question_id: data,
    }
    commit(SET_NEW_QUESTIONS, newQuestion)
  },

  async getMappingQuestionAnswers({ commit }, id) {
    const { data } = await getMappingQuestionAnswerApi(id)
    commit(SET_CURRENT_MAPPING_QUESTION_ANSWER, data)
  },

  async postMappingAnswer(_, body) {
    await postMappingAnswerApi(body)
  },

  async getMappingCriteria({ commit, rootState }) {
    const { data } = await getMappingCriteriaApi(rootState.backoffice.currentClient.brandId)
    commit(SET_MAPPING_CRITERIA, data)
  },

  //Contacts
  async getSegments({ commit, rootState }) {
    const { data } = await getSegmentsApi(rootState.backoffice.currentClient.brandId)
    commit(SET_SEGMENTS, data)
  },
  setProfiles({ commit }, profiles) {
    commit(SET_PROFILES, profiles)
  },
  async updateOptin({ commit, rootState }, { profile, optinValue }) {
    const optin = {
      optin: optinValue,
    }
    await postOptinApi(rootState.backoffice.currentClient.brandId, profile.id, optin)

    commit(UPDATE_PROFILES, { item: profile, optinValue })
  },
  async getProfiles({ rootState, commit, state }, { tableParams }) {
    const urlParams = tableOptions(tableParams)
    const { data } = await postProfilesApi(
      rootState.backoffice.currentClient.brandId,
      state.currentSegment.rules,
      urlParams
    )
    commit(SET_PROFILES, data.rows)
    delete data.rows
    commit(SET_PROFILES_PAGINATION, data)
  },
  async getExportProfiles({ dispatch }, segment) {
    dispatch('setCurrentSegment', segment)
    await dispatch('getProfiles', { tableParams: null })
  },
  setCurrentSegment({ commit }, currentSegment) {
    commit(SET_CURRENT_SEGMENT, currentSegment)
  },
  setFilters({ commit, state }, filters) {
    const currentSegment = {
      ...state.currentSegment,
      rules: {
        ...state.currentSegment.rules,
        filters,
      },
    }
    commit(SET_CURRENT_SEGMENT, currentSegment)
  },
  addSegment({ commit, rootState }) {
    const newSegment = {
      id: 0,
      brand_id: rootState.backoffice.currentClient.brandId,
      name: `temp-${formatedDate('now', 'L-LT')}`,
      rules: getDefaultState().currentSegment.rules,
      saved: false,
    }
    commit(ADD_SEGMENT, newSegment)
    commit(SET_CURRENT_SEGMENT, newSegment)
  },
  async createSegment({ rootState, dispatch, commit }, segment) {
    const { data } = await putSegmentApi(rootState.backoffice.currentClient.brandId, segment)
    await dispatch('getSegments')
    commit(SET_CURRENT_SEGMENT, data)
  },
  async updateSegment({ rootState, dispatch, commit }, segment) {
    const { data } = await postSegmentApi(rootState.backoffice.currentClient.brandId, segment.id, segment)
    await dispatch('getSegments')
    commit(SET_CURRENT_SEGMENT, data)
  },
  async deleteSegment({ rootState, state, commit, dispatch }) {
    await deleteSegmentApi(rootState.backoffice.currentClient.brandId, state.currentSegment.id)
    commit(SET_CURRENT_SEGMENT, getDefaultState().currentSegment)
    dispatch('getSegments')
  },
  async getExportFile({ rootState }, data) {
    const file = await postExportApi(rootState.backoffice.currentClient.brandId, data)
    const fileName = data.filename || `export_${formatedDate('now', 'DDMMYYYY')}`
    let fileExtension = data.extension || 'xlsx'
    if (data.password) {
      fileExtension = 'zip'
    }
    downloadFile(file, `${fileName}.${fileExtension}`)
  },
  //Report
  async getFile(_, { token, btoken }) {
    await getFileApi(token, btoken)
  },

  async setCurrentProfile({ rootState, commit }, id) {
    const { data } = await getProfileApi(rootState.backoffice.currentClient.brandId, id)
    data.id = id
    commit(SET_CURRENT_PROFILE, data)
  },

  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

const getters = {
  //Dashboard
  totalProfilesChart: state => {
    const series = []
    const categories = []
    state.dashboard.totalProfiles.monthes?.forEach(m => {
      series.push(m.count)
      categories.push(formatedDate(m.month, 'MM/YYYY'))
    })
    return {
      series,
      categories,
    }
  },
  optInCustomChart: state => {
    const series = []
    const categories = []
    state.dashboard.optInCustom.optin?.forEach(o => {
      series.push(o.count)
      categories.push(formatedDate(o.period, 'L'))
    })
    return {
      series,
      categories,
    }
  },
  optInProportionChart: state => {
    const series = []
    const categories = []
    state.dashboard.optInProportion.optin?.forEach(o => {
      series.push(o.percent)
      categories.push(formatedDate(o.period, 'L'))
    })
    return {
      series,
      categories,
    }
  },
  averageKnowledgeChart: state => {
    const series = []
    const categories = []
    state.dashboard.averageKnowledge.knowledge?.forEach(k => {
      series.push(k.percent)
      categories.push(formatedDate(k.period, 'L'))
    })
    return {
      series,
      categories,
    }
  },
  gendersChart: state => {
    const series = []
    const categories = []
    state.dashboard.genders.forEach(g => {
      series.push(g.count)
      categories.push(g.gender)
    })
    return {
      series,
      categories,
    }
  },
  agesChart: state => {
    const series = []
    const categories = []
    state.dashboard.ages.forEach(a => {
      series.push(a.count)
      categories.push(a.age)
    })
    return {
      series,
      categories,
    }
  },
  countriesChart: state => {
    const series = []
    const categories = []
    state.dashboard.countries.forEach(c => {
      series.push(c.count)
      categories.push(c.country)
    })
    return {
      series,
      categories,
    }
  },
  //Contacts
  currentSegmentName: state => {
    return state.currentSegment.name || null
  },
  tmpSegment: state => {
    return state.segments.some(s => s.saved === false)
  },
  cantSaveSegment: state => {
    const segment = state.segments.find(s => s.id === state.currentSegment.id)
    return JSON.stringify(segment) === JSON.stringify(state.currentSegment)
  },
  totalProfiles: state => {
    return state.profilesPagination?.total || 0
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
