import qs from 'qs'
import { removeEmpty } from '@/utils/helper.util'

export const tableOptions = (tableParams, mode = 'profilehub') => {
  const options = tableParams || {}
  let urlParams = {
    page: options.page,
  }

  if (mode === 'profilehub') {
    urlParams = {
      ...urlParams,
      limit: options.itemsPerPage,
      sortBy: options.sortBy?.length ? options.sortBy[0].split('.').pop() : null,
      sortDesc: options.sortDesc?.length ? options.sortDesc[0] : null,
    }
  }
  if (mode === 'touchpoints') {
    urlParams = {
      ...urlParams,
      limit: options.itemsPerPage,
      sortBy: options.sortBy?.length ? options.sortBy[0] : null,
      sortDesc: options.sortDesc?.length ? options.sortDesc[0] : true,
    }
  }
  if (mode === 'autoreply') {
    urlParams = {
      ...urlParams,
      size: options.itemsPerPage,
      sort: sortColumns(options.sortBy, options.sortDesc) || null,
    }
  }
  if (mode === 'leads') {
    urlParams = {
      ...urlParams,
      itemsPerPage: options.itemsPerPage,
    }
  }

  const stringParams = Object.entries(urlParams)
    .filter(([, urlParamValue]) => urlParamValue !== undefined && urlParamValue !== null)
    .map(([urlParamName, urlParamValue]) => `${urlParamName}=${urlParamValue}`)
    .join('&')

  return stringParams ? `&${stringParams}` : ''
}

export const filterOptions = (filters, mode = 'autoreply') => {
  const options = filters || {}
  let urlParams = {}

  if (mode === 'autoreply') {
    urlParams = {
      origins: options.origins?.join(',') || null,
      grades: options.grades?.join(',') || null,
      shops: options.shops?.join(',') || null,
      shop_group: options.shop_group?.join(',') || null,
      from: options.from,
      to: options.to,
      response_status: options.response_status,
      with_comment: options.with_comment?.join(',') || null,
    }
  }
  if (mode === 'leads') {
    urlParams = {
      ...options,
      status: options.status || null,
      search: options.search || null,
      from: options.from || null,
      to: options.to || null,
      locationId: options.locationId || null,
      tags: options.tags || null,
      fields: options.fields || null,
    }
  }

  const cleanedParams = removeEmpty(urlParams)
  const stringParams = qs.stringify(cleanedParams, { encode: false })

  return stringParams ? `&${stringParams}` : ''
}

const sortColumns = (sortBy, sortDesc) => {
  return sortBy.map((s, i) => `${s},${sortDesc[i] ? 'DESC' : 'ASC'}`).join(';')
}

// FOR THE NEW API IN SYMFONY

export const buildCoreApiParams = ({ params = {}, query = [], exists = [], options = {} }) => {
  const paramsBuild = Object.values(params)
    .map(param => {
      return `/${param}`
    })
    .join('')

  const existsBuild = exists
    .map((e, index) => {
      return `${index > 0 ? ',exists' : 'exists'}${e.key}=${e.value}`
    })
    .join('')

  const queryBuild = query
    .map((q, index) => {
      return `${index === 0 && !existsBuild ? '' : '&'}${q.key}=${q.value}`
    })
    .join('')

  const optionsBuild = Object.entries(options)
    .map((option, index) => {
      return `${index === 0 && !queryBuild ? '' : '&'}${option[0]}=${option[1]}`
    })
    .join('')

  return paramsBuild + existsBuild + queryBuild + optionsBuild
}
