import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
  sendDeleteRequest,
  sendPatchRequest,
} from '@/services/api.service'
import { API_PROFILE_HUB } from '@/config/api.config'

//Dashboard
export const getDashboardApi = brandId => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.DASHBOARD}?brandId=${brandId}`)
}

//Import
export const postImportApi = (brandId, data) => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.IMPORT}?brand_id=${brandId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const postTemplateApi = data => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CREATE_TEMPLATE}`, data, {
    responseType: 'blob',
  })
}

//Criteria
export const getCriteriaGtrApi = () => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_GTR}?search=&join=gtr_criterias_gtr_locale:gtr_criterias_gtr.id|id_critere`
  )
}

export const getCriteriaCustomApi = brandId => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_CUSTOM}?brand_id=${brandId}&search=brand_id:${brandId}`
  )
}

export const getBrandCriteriaApi = brandId => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_BRAND}?brand_id=${brandId}`)
}

export const getIdCriteriaApi = id => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_BRAND}?id=${id}`)
}

export const postNewCriteriaApi = (brandId, newCriteria) => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_BRAND}`, {
    ...newCriteria,
    brand_id: brandId,
  })
}

export const patchCriteriaApi = (id, updatedCriteria) => {
  return sendPatchRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CRITERIA_BRAND}?id=${id}`, {
    ...updatedCriteria,
  })
}

//Campaigns
export const getCampaignApi = (brand_id, type, origin) => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CAMPAIGN_MAPPING}?brand_id=${brand_id}&type=${type}&origin=${origin}`
  )
}

export const postCampaignApi = (id, body) => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.CAMPAIGN_MAPPING}?id=${id}`, body)
}

export const getMappingApi = (mappingOptions, id) => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER}?brand_id=${mappingOptions.brand_id}&origin=${mappingOptions.origin}&type=${mappingOptions.type}&id=${id}`
  )
}

export const postMappingApi = (mappingOptions, name, feedback_template_id) => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER}`, {
    ...mappingOptions,
    name,
    feedback_template_id,
  })
}

export const getMappingQuestionApi = id => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER_QUESTION}?gtr_mapping_transfer_id=${id}`
  )
}

export const deleteMappingQuestionApi = (id, gtr_mapping_transfer_id) => {
  return sendDeleteRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER_QUESTION}?gtr_mapping_transfer_id=${gtr_mapping_transfer_id}&id=${id}`
  )
}

export const postMappingQuestionApi = updatedQuestion => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER_QUESTION}`, {
    ...updatedQuestion,
  })
}

export const getMappingQuestionAnswerApi = id => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER_QUESTION_ANSWER}?gtr_mapping_transfer_question_id=${id}`
  )
}

export const postMappingAnswerApi = updatedAnswer => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_TRANSFER_QUESTION_ANSWER}`, {
    ...updatedAnswer,
  })
}

export const getMappingCriteriaApi = brand_id => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.MAPPING_CRITERIA}?brand_id=${brand_id}`)
}

export const getAvailableQA = feedback_template_id => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.AVAILABLE_V1_QA}?feedback_template_id=${feedback_template_id}`
  )
}
//Contacts
export const postProfilesApi = (brandId, segment, urlParams) => {
  return sendPostRequest(`${API_PROFILE_HUB.BASE_API_PROXY_URL}${API_PROFILE_HUB.PROFILES}?${urlParams}`, {
    brandId,
    segment,
  })
}

export const getSegmentsApi = brandId => {
  return sendGetRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.SEGMENTS}?brand_id=${brandId}&search=brand_id:${brandId}`
  )
}

export const putSegmentApi = (brandId, segment) => {
  return sendPutRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.SEGMENTS}?brand_id=${brandId}`, segment)
}

export const postSegmentApi = (brandId, segmentId, segment) => {
  return sendPostRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.SEGMENTS}?brand_id=${brandId}&search=id:${segmentId}`,
    segment
  )
}

export const deleteSegmentApi = (brandId, segmentId) => {
  return sendDeleteRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.SEGMENTS}?brand_id=${brandId}&search=id:${segmentId}`
  )
}

export const postOptinApi = (brandId, profileId, optin) => {
  return sendPostRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.OPTIN}?brand_id=${brandId}&id=${profileId}`,
    optin
  )
}

export const postExportApi = (brandId, data) => {
  return sendPostRequest(
    `${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.EXPORT_PROFILES}?brand_id=${brandId}`,
    data,
    {
      responseType: 'blob',
    }
  )
}

export const getProfileApi = (brandId, id) => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.PROFILE}?brand_id=${brandId}&id=${id}`)
}

//Report
export const getFileApi = (token, btoken) => {
  return sendGetRequest(`${API_PROFILE_HUB.BASE_API_URL}${API_PROFILE_HUB.FILE}?token=${token}&btoken=${btoken}`)
}
