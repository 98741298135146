<template>
  <v-snackbar
    class="tw-mt-2 sm:tw-mt-4 alert"
    :timeout="5000"
    :value="alert.visible"
    top
    :color="alert.color"
    @input="onInput"
  >
    <span v-dompurify-html="purifyAndAllowHtmlTags(alert.text)"></span>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { purifyAndAllowHtmlTags } from '@/utils/purifyString.util'

export default {
  name: 'Alert',
  data() {
    return {
      purifyAndAllowHtmlTags,
    }
  },
  computed: {
    ...mapState({
      alert: state => state.backoffice.alert,
    }),
  },
  methods: {
    ...mapActions({
      resetAlert: 'backoffice/resetAlert',
    }),
    onInput() {
      this.resetAlert()
    },
  },
}
</script>

<style lang="scss">
.alert {
  .v-snack {
    &__content {
      text-align: center;
    }
  }
}
</style>
