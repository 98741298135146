import {
  getUsersApi,
  getUserApi,
  postUserApi,
  patchUserApi,
  getRolesApi,
  getUserAssociationApi,
  getUserMySelfsApi,
  getUserMenusApi,
} from '@/services/users.service'
import { postSendInvitationApi } from '@/services/auth.service'
import { buildCoreApiParams } from '@/utils/table.util'

const SET_USERS = 'SET_USERS'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_ROLES = 'SET_ROLES'
const SET_ASSOCIATIONS = 'SET_ASSOCIATIONS'
const SET_USER_MYSELF = 'SET_USER_MYSELF'
const SET_MENUS = 'SET_MENUS'

const getDefaultState = () => {
  return {
    users: [],
    currentUser: null,
    roles: [],
    associations: [],
    menus: [],
    userMySelf: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_USERS]: (state, users) => {
    state.users = users
  },
  [SET_CURRENT_USER]: (state, currentUser) => {
    state.currentUser = currentUser
  },
  [SET_ROLES]: (state, roles) => {
    state.roles = roles
  },
  [SET_ASSOCIATIONS]: (state, associations) => {
    state.associations = associations
  },
  [SET_MENUS]: (state, menus) => {
    state.menus = menus
  },
  [SET_USER_MYSELF]: (state, user) => {
    state.userMySelf = user
  },
}

const actions = {
  async getUsers({ commit }, { query, options }) {
    const urlParams = buildCoreApiParams({ query, options })
    const users = await getUsersApi(urlParams)
    commit(SET_USERS, users)
  },
  async getUser({ commit }, { params }) {
    const urlParams = buildCoreApiParams({ params })
    const currentUser = await getUserApi(urlParams)
    commit(SET_CURRENT_USER, currentUser)
  },
  async postUser(_, body) {
    await postUserApi(body)
  },
  async patchUser(_, { params, body }) {
    const urlParams = buildCoreApiParams({ params })
    await patchUserApi(urlParams, body)
  },
  async getUserMySelf({ commit }) {
    const data = await getUserMySelfsApi()
    commit('SET_USER_MYSELF', data)
  },
  async getRoles({ commit }) {
    const roles = await getRolesApi()
    commit(SET_ROLES, roles)
  },
  async getUserAssociation({ commit }, { query }) {
    const urlParams = buildCoreApiParams({ query })
    const { data } = await getUserAssociationApi(urlParams)
    commit(SET_ASSOCIATIONS, data)
  },
  resetUserAssociation({ commit }) {
    commit(SET_ASSOCIATIONS, [])
  },
  async postUserInvitation(_, { email, token }) {
    await postSendInvitationApi({ email, token })
  },
  async getUserMenus({ commit }, role) {
    const menus = await getUserMenusApi(role)
    commit(SET_MENUS, menus)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
