import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYears from 'dayjs/plugin/weekOfYear'
import i18n from '@/i18n/i18n'

dayjs.extend(weekOfYears)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)

/**
 * Returns the string of formated date, in the requested locale
 * @param {string} date date to parse
 * @param {string} locale locale to use for display
 * @returns {string} Parsed and formatted time
 */
export const formatedDate = (date, format, locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)
  dayjs.locale(locale)

  return date === 'now' ? dayjs().format(format) : dayjs(date).format(format)
}

/**
 * Returns the string of relative time from now, in the requested locale
 * @param {string} date date to parse
 * @param {string} locale locale to use for display
 * @returns {string} Parsed and formatted time
 */
export const timeFromNow = (date, suffix = false, locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)

  return dayjs(date).locale(locale).fromNow(!suffix)
}

/**
 * It returns an array of two dates, the first being the start of the time unit and the second being
 * the end of the time unit
 * @param substractedCount - The number of time units to subtract from the current date.
 * @param timeUnit - The time unit to use. Can be one of the following: year, month, week, day, hour,
 * minute, second, millisecond
 * @returns An array of two dates.
 */
export const getPreviousDateRange = (substractedCount, timeUnit, availableDateRange = null, locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)
  dayjs.locale(locale)

  const dateRange = [
    dayjs().subtract(substractedCount, timeUnit).startOf(timeUnit),
    dayjs().subtract(substractedCount, timeUnit).endOf(timeUnit),
  ]

  if (availableDateRange?.min && dateRange[0].isBefore(dayjs(availableDateRange.min))) {
    dateRange[0] = dayjs(availableDateRange.min)
  }

  if (availableDateRange?.max && dateRange[1].isAfter(dayjs(availableDateRange.max))) {
    dateRange[1] = dayjs(availableDateRange.max)
  }

  return dateRange
}

export const getISODate = date => {
  return dayjs(date).toISOString()
}

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000)
}

export function millisecondsToDays(milliseconds) {
  if (!milliseconds) return ''

  const msPerHour = 1000 * 60 * 60
  const durationInDays = Math.round(milliseconds / msPerHour / 24)
  const durationInHours = Math.round(milliseconds / msPerHour)

  if (durationInDays >= 1) {
    return `${durationInDays} ${i18n.tc('label.timeUnitDays', durationInDays)}`
  }
  if (durationInHours >= 1) {
    return `${durationInHours} ${i18n.tc('label.timeUnitHours', durationInHours)}`
  }

  return ''
}
