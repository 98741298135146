export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

export const downloadFile = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') return window.navigator.msSaveBlob(blob, filename)

  const blobURL =
    window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)
  let tempLink = document.createElement('a')

  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()

  setTimeout(function () {
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }, 200)
}

export const getFileName = (headers, defaultFileExtension = 'csv') => {
  let filename = `default.${defaultFileExtension}`
  const disposition = headers['content-disposition']

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  return filename
}

export const stringToSlug = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]+/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const debounce = (func, wait, immediate, context) => {
  let result
  let timeout = null
  return function () {
    const ctx = context || this,
      args = arguments
    const later = () => {
      timeout = null
      if (!immediate) result = func.apply(ctx, args)
    }
    const callNow = immediate && !timeout
    // Tant que la fonction est appelée, on reset le timeout.
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) result = func.apply(ctx, args)
    return result
  }
}

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

export const getShortLocal = locale => locale.substring(0, 2)

export const deepMerge = (a, b) =>
  [a, b].reduce(
    (reducer, o) =>
      Object.entries(o).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: v && typeof v === 'object' ? deepMerge(acc[k] || {}, v) : v,
        }),
        reducer
      ),
    {}
  )

export const formatBytes = (bytes, decimals = 2, locale = 'en') => {
  if (bytes === 0) return locale === 'fr' ? '0 octet' : '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizesFR = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
  const sizesAll = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${locale === 'fr' ? sizesFR[i] : sizesAll[i]}`
}

export const objectHaveLength = object => {
  return object && Object.keys(object).length > 0
}

export const hexToRgbA = (hex, alpha) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
  }
  throw new Error('Bad Hex')
}
