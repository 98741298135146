export const defaultLexicon = {
  location: {
    en: 'location',
    fr: 'établissement',
    es: 'establecimiento',
    it: 'stabilimento',
    de: 'einrichtung',
    no: 'plassering',
  },
  locations: {
    en: 'locations',
    fr: 'établissements',
    es: 'establecimientos',
    it: 'stabilimenti',
    de: 'einrichtungen',
    no: 'plasseringer',
  },
  skus: {
    en: 'product SKUs',
    fr: 'SKUs produits',
    es: 'SKUs de los productos',
    it: 'SKUs prodotti',
    de: 'produkt SKUs',
    no: 'produkt SKUs',
  },
  tags: {
    en: 'tags',
    fr: 'tags',
    es: 'tags',
    it: 'tags',
    de: 'tags',
    no: 'etiketter',
  },
  additionalData: {
    en: 'additional data',
    fr: 'données additionnelles',
    es: 'datos complementarios',
    it: 'dati aggiuntivi',
    de: 'zusätzliche Daten',
    no: 'tilleggsdata',
  },
  fieldValues: {
    en: 'field values',
    fr: 'valeurs des champs',
    es: 'valores de los campos',
    it: 'valori dei campi',
    de: 'werte der Felder',
    no: 'feltverdier',
  },
  identity: {
    en: 'identity',
    fr: 'identité',
    es: 'identidad',
    it: 'identità',
    de: 'identität',
    no: 'identitet',
  },
  company: {
    en: 'company name',
    fr: 'entreprise',
    es: 'empresa',
    it: 'azienda',
    de: 'unternehmen',
    no: 'selskap',
  },
  subscriber: {
    en: 'administrator',
    fr: 'administrateur',
    es: 'administrador',
    it: 'amministratore',
    de: 'administrator',
    no: 'administrator',
  },
  observer: {
    en: 'supervisor',
    fr: 'superviseur',
    es: 'supervisor',
    it: 'supervisore',
    de: 'vorgesetzter',
    no: 'veileder',
  },
}
