import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_TOUCHPOINTS } from '@/config/api.config'

//Brands
export const getBrandSettingsApi = brandId => {
  return sendGetRequest(`${API_TOUCHPOINTS.BASE_API_URL}${API_TOUCHPOINTS.BRAND_SETTINGS}?brandId=${brandId}`)
}

//Products
export const getProductsApi = (brandId, sku) => {
  return sendGetRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_PRODUCT_URL}${API_TOUCHPOINTS.PRODUCT}?sku=${sku}&brandId=${brandId}`
  )
}

//Touchpoints
export const getSearchFiltersApi = brandId => {
  return sendGetRequest(`${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.SEARCH_FILTERS}?brandId=${brandId}`)
}

export const postCreateProductTouchpointApi = (brandId, sku) => {
  return sendPostRequest(`${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.PRODUCT_TOUCHPOINT}?type=write`, {
    sku: sku,
    brandId: brandId,
  })
}

export const postCreateCustomTouchpointApi = (brandId, touchpoint) => {
  return sendPostRequest(`${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.CUSTOM_TOUCHPOINT}?type=write`, {
    brandId,
    destUrl: touchpoint.destUrl,
    configurationSlug: touchpoint.configurationSlug,
    sourceSuffix: touchpoint.sourceSuffix,
    description: touchpoint.description,
    quantity: touchpoint.quantity,
  })
}

export const postSearchTouchpointApi = (brandId, search = [], filters = [], urlParams = '') => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.SEARCH_TOUCHPOINTS}?${urlParams}`,
    createRequestBody(brandId, null, search, filters)
  )
}

export const getTouchpointApi = (brandId, id) => {
  return sendGetRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.FIND_TOUCHPOINT}?_id=${id}&brandId=${brandId}`
  )
}

export const postUpdateTouchpointApi = touchpoint => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.UPDATE_TOUCHPOINT}?type=write`,
    touchpoint
  )
}

// Zip
export const postDownloadZipApi = (brandId, _ids = [], search = [], filters = []) => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.DOWNLOAD_ZIP}`,
    createRequestBody(brandId, _ids, search, filters)
  )
}

// Export
export const postExportCSVApi = (brandId, _ids = [], search = [], filters = []) => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.EXPORT_TOUCHPOINT}`,
    createRequestBody(brandId, _ids, search, filters)
  )
}

// Import
export const postImportCSVApi = (brandId, data) => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.IMPORT_TOUCHPOINT}?brandId=${brandId}&type=write`,
    data
  )
}

// Landing pages
export const postRefreshLPApi = (brandId, _ids = [], search = [], filters = []) => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.REFRESH_LANDINGPAGE}?type=write`,
    createRequestBody(brandId, _ids, search, filters)
  )
}

// Qr codes
export const postRefreshQRApi = (brandId, _ids = [], search = [], filters = []) => {
  return sendPostRequest(
    `${API_TOUCHPOINTS.BASE_API_PROXY_URL}${API_TOUCHPOINTS.REFRESH_QRCODES}?type=write`,
    createRequestBody(brandId, _ids, search, filters)
  )
}

// Tools
export const createRequestBody = (brandId, _ids = [], search = [], filters = []) => {
  return {
    brandId,
    ...(_ids && { _ids }),
    searchFilter: {
      condition: 'and',
      filterGroups: [
        {
          condition: 'or',
          filters: search,
        },
        {
          condition: 'and',
          filters: filters,
        },
      ],
    },
  }
}
