import {
  getReviewIdApi,
  postReviewIdApi,
  postSendReplyApi,
  postSaveReplyApi,
  postSendAllReplyApi,
  getReplyApi,
  getBrandUuidApi,
  getSettingsApi,
  postSettingsApi,
  getReviewsApi,
  getFiltersApi,
  getExportApi,
  postReportResponseApi,
  getDeactivateReviewApi,
} from '@/services/autoreply.service'
import { tableOptions, filterOptions } from '@/utils/table.util'
import { downloadFile } from '@/utils/utilities.util'
import { formatedDate } from '@/utils/date.util'

const SET_UUID = 'SET_UUID'
const SET_CURRENT_REVIEW = 'SET_CURRENT_REVIEW'
const SET_SETTINGS = 'SET_SETTINGS'
const SET_REVIEWS = 'SET_REVIEWS'
const SET_REVIEWS_PAGINATION = 'SET_REVIEWS_PAGINATION'
const SET_AVAILABLE_FILTERS = 'SET_AVAILABLE_FILTERS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    reviews: [],
    currentReview: null,
    reviewsPagination: null,
    uuid: null,
    settings: null,
    availableFilters: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_CURRENT_REVIEW]: (state, review) => {
    state.currentReview = review
  },
  [SET_UUID]: (state, uuid) => {
    state.uuid = uuid
  },
  [SET_SETTINGS]: (state, settings) => {
    state.settings = {
      ...state.settings,
      ...settings,
    }
  },
  [SET_REVIEWS]: (state, reviews) => {
    state.reviews = reviews
  },
  [SET_REVIEWS_PAGINATION]: (state, pagination) => {
    state.reviewsPagination = pagination
  },
  [SET_AVAILABLE_FILTERS]: (state, filters) => {
    state.availableFilters = filters
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  // Semantic
  async getUuid({ commit, rootState }) {
    try {
      const { data } = await getBrandUuidApi(rootState.backoffice.currentClient.brandId)
      commit(SET_UUID, data.bot_id)
    } catch {
      commit(SET_UUID, null)
    }
  },

  //Settings
  async getSettings({ commit, rootState }) {
    const { data } = await getSettingsApi(rootState.backoffice.currentClient.brandId)
    commit(SET_SETTINGS, data)
  },
  async setSettings({ commit }, settings) {
    commit(SET_SETTINGS, settings)
  },
  async updateSettings({ commit, rootState, state }) {
    const { data } = await postSettingsApi(rootState.backoffice.currentClient.brandId, state.settings)
    commit(SET_SETTINGS, data)
  },

  //Reviews
  async getReviews({ commit, rootState }, { tableParams, filters }) {
    const urlParams = tableOptions(tableParams, 'autoreply')
    const filterParams = filterOptions(filters, 'autoreply')
    const { data } = await getReviewsApi(rootState.backoffice.currentClient.brandId, urlParams, filterParams)
    commit(SET_REVIEWS, data.results)
    delete data.results
    commit(SET_REVIEWS_PAGINATION, data)
  },
  async getFilters({ commit, rootState }) {
    const { data } = await getFiltersApi(rootState.backoffice.currentClient.brandId)
    commit(SET_AVAILABLE_FILTERS, data)
  },
  async getExport({ rootState }, filters) {
    const filterParams = filterOptions(filters, 'autoreply')
    const file = await getExportApi(rootState.backoffice.currentClient.brandId, '', `${filterParams}&mode=export`)
    const fileName = `export_${formatedDate('now', 'DDMMYYYY')}`
    const fileExtension = 'xlsx'
    downloadFile(file, `${fileName}.${fileExtension}`)
  },
  async getReview({ commit, rootState }, id) {
    const { data } = await getReviewIdApi(rootState.backoffice.currentClient.brandId, id)
    commit(SET_CURRENT_REVIEW, data)
  },
  async updateReview({ rootState, state }, body) {
    await postReviewIdApi(rootState.backoffice.currentClient.brandId, state.currentReview.id, body)
  },
  async sendResponse({ rootState, state }, payload) {
    await postSendReplyApi(rootState.backoffice.currentClient.brandId, state.currentReview.id, payload)
  },
  async saveResponse({ rootState, state }, payload) {
    await postSaveReplyApi(rootState.backoffice.currentClient.brandId, state.currentReview.id, payload)
  },
  async sendAllReply({ rootState, state }, filters) {
    const filterParams = filterOptions(filters, 'autoreply')
    const { data } = await postSendAllReplyApi(
      rootState.backoffice.currentClient.brandId,
      {
        maxDate: state.reviewsPagination.max_date_ready,
      },
      filterParams
    )
    return data
  },
  async rerollResponse({ commit, rootState, state }) {
    const { data } = await getReplyApi(rootState.backoffice.currentClient.brandId, state.currentReview.id)
    commit(SET_CURRENT_REVIEW, data)
  },
  async reportResponse({ rootState }, payload) {
    await postReportResponseApi(rootState.backoffice.currentClient.brandId, payload)
  },
  async deactivateReview({ rootState }, id) {
    await getDeactivateReviewApi(rootState.backoffice.currentClient.brandId, id)
  },
  resetReview({ commit }) {
    commit(SET_CURRENT_REVIEW, null)
  },

  //Reset State
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

const getters = {
  parentView: state => {
    return state.currentView.split('_').shift()
  },
  childView: state => {
    return state.currentView.split('_').pop()
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
