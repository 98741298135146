import { tableOptions, filterOptions } from '@/utils/table.util'
import { downloadFile, getFileName } from '@/utils/utilities.util'
import { DashboardMode } from '@/config/leads.config'
import {
  getLeadsFormsApi,
  getFormFieldsApi,
  setFormFieldsApi,
  getFormStaticsApi,
  setFormStaticsApi,
  getFormLeadsApi,
  getLeadExportApi,
  putLeadStatusApi,
  putLeadOwnerApi,
  putLeadLocationApi,
  putLeadEditApi,
  getLeadsFiltersApi,
  getFormApi,
  getLeadApi,
  getFormSubmissionApi,
  putFormSettingsApi,
  putFormLocationSettingsApi,
  putFormNotificationsApi,
  putFormTranslationsApi,
  getAdministrators,
  getConditionableFieldsApi,
  getDynamicTranslationsApi,
  getConversionDashboardApi,
  getLPELocationsApi,
  deleteLeadApi,
  getFormDefaultTranslations,
} from '@/services/leads.service'
import { getShortLocal } from '@/utils/utilities.util'
import i18n from '@/i18n/i18n'
import { getPropertyByLocale } from '@/utils/helper.util'
import { defaultLexicon } from '@/config/formLexicon.config'
import { capitalize } from '@/utils/formatter.util'
import { defaultLangList } from '@/config/formTranslations.config'

const GET_LEADS_FORMS = 'GET_LEADS_FORMS'
const GET_FORM_LEADS = 'GET_FORM_LEADS'
const GET_CONDITIONABLE_FIELDS = 'GET_CONDITIONABLE_FIELDS'
const GET_DYNAMIC_TRANSLATIONS = 'GET_DYNAMIC_TRANSLATIONS'
const GET_LPE_LOCATIONS = 'GET_LPE_LOCATIONS'
const GET_ADMINISTRATORS = 'GET_ADMINISTRATORS'
const GET_CURRENT_FORMS_STATICS = 'GET_CURRENT_FORMS_STATICS'
const GET_CURRENT_FORMS_FIELDS = 'GET_CURRENT_FORMS_FIELDS'
const SET_CURRENT_FORM = 'SET_CURRENT_FORM'
const SET_CURRENT_FORM_FIELDS = 'SET_CURRENT_FORM_FIELDS'
const SET_CURRENT_FORM_STATICS = 'SET_CURRENT_FORM_STATICS'
const SET_CURRENT_FORM_NOTIFICATIONS = 'SET_CURRENT_FORM_NOTIFICATIONS'
const SET_CURRENT_FORM_TRANSLATIONS = 'SET_CURRENT_FORM_TRANSLATIONS'
const SET_CURRENT_FORM_SETTINGS = 'SET_CURRENT_FORM_SETTINGS'
const SET_CURRENT_FORM_LOCATION_SETTINGS = 'SET_CURRENT_FORM_LOCATION_SETTINGS'
const SET_CURRENT_LEAD = 'SET_CURRENT_LEAD'
const SET_CURRENT_FORM_SUBMISSION = 'SET_CURRENT_FORM_SUBMISSION'
const SET_LEADS_GENERIC_FILTER = 'SET_LEADS_GENERIC_FILTER'
const SET_LEADS_FIELD_FILTERS = 'SET_LEADS_FIELD_FILTERS'
const SET_CURRENT_FILTERS = 'SET_CURRENT_FILTERS'
const SET_CURRENT_DATE_RANGE = 'SET_CURRENT_DATE_RANGE'
const SET_SEARCH = 'SET_SEARCH'
const RESET_STATE = 'RESET_STATE'
const SET_DASHBOARD_DISPLAY_TYPE = 'SET_DASHBOARD_DISPLAY_TYPE'
const SET_CONVERSION_DASHBOARD = 'SET_CONVERSION_DASHBOARD'
const SET_ACTION_DRAWER_OPENED = 'SET_ACTION_DRAWER_OPENED'
const SET_CURRENT_ACTION_DRAWER = 'SET_CURRENT_ACTION_DRAWER'
const SET_CURRENT_FORM_LEXICON = 'SET_CURRENT_FORM_LEXICON'
const SET_CURRENT_LOCALE_TAB = 'SET_CURRENT_LOCALE_TAB'
const SET_FORM_DEFAULT_TRANSLATIONS = 'SET_FORM_DEFAULT_TRANSLATIONS'
const SET_FORM_MANDATORY_TRANSLATIONS = 'SET_FORM_MANDATORY_TRANSLATIONS'

const getDefaultState = () => {
  return {
    formsList: {
      total: null,
      rows: [],
      paginated: null,
    },
    currentFilters: [],
    currentLeadsList: {
      itemsPerPage: null,
      total: null,
      paginated: null,
      rows: [],
      headers: [],
    },
    administrators: [],
    currentLead: null,
    currentFormSubmission: null,
    currentForm: null,
    currentFormFields: [],
    currentFormStatics: [],
    currentFormLexicon: null,
    genericFilters: [],
    fieldFilters: [],
    currentDateRange: null,
    search: '',
    conditionableFields: null,
    dynamicTranslations: {},
    dashboardDisplayType: DashboardMode.VOLUME,
    conversionDashboard: {},
    actionDrawerOpened: false,
    currentActionDrawer: null,
    lpeLocations: [],
    currentLocaleTab: null,
    defaultTranslations: {},
    mandatoryTranslations: [],
  }
}

const state = getDefaultState()

const mutations = {
  [GET_LEADS_FORMS]: (state, formsList) => {
    state.formsList = formsList
  },
  [GET_FORM_LEADS]: (state, currentLeadsList) => {
    state.currentLeadsList = currentLeadsList
  },
  [GET_CONDITIONABLE_FIELDS]: (state, conditionableFields) => {
    state.conditionableFields = conditionableFields
  },
  [GET_DYNAMIC_TRANSLATIONS]: (state, dynamicTranslations) => {
    state.dynamicTranslations = dynamicTranslations
  },
  [GET_LPE_LOCATIONS]: (state, lpeLocations) => {
    state.lpeLocations = lpeLocations
  },
  [GET_ADMINISTRATORS]: (state, administrators) => {
    state.administrators = administrators
  },
  [SET_CURRENT_FORM]: (state, currentForm) => {
    state.currentForm = currentForm
  },
  [GET_CURRENT_FORMS_FIELDS]: (state, formFields) => {
    state.currentFormFields = formFields
  },
  [SET_CURRENT_FORM_FIELDS]: (state, currentFormFields) => {
    state.currentFormFields = currentFormFields
  },
  [SET_CURRENT_FORM_STATICS]: (state, currentFormStatics) => {
    state.currentFormStatics = currentFormStatics
  },
  [GET_CURRENT_FORMS_STATICS]: (state, formStatics) => {
    state.currentFormStatics = formStatics
  },
  [SET_CURRENT_FORM_NOTIFICATIONS]: (state, notifications) => {
    state.currentForm.notifications = notifications
  },
  [SET_CURRENT_FORM_TRANSLATIONS]: (state, translations) => {
    state.currentForm.translations = translations
  },
  [SET_CURRENT_FORM_SETTINGS]: (state, settings) => {
    state.currentForm.settings = settings
  },
  [SET_CURRENT_FORM_LOCATION_SETTINGS]: (state, locationSettings) => {
    state.currentForm.locationSettings = locationSettings
  },
  [SET_LEADS_GENERIC_FILTER]: (state, genericFilters) => {
    state.genericFilters = genericFilters
  },
  [SET_LEADS_FIELD_FILTERS]: (state, fieldFilters) => {
    state.fieldFilters = fieldFilters
  },
  [SET_CURRENT_FILTERS]: (state, currentFilters) => {
    state.currentFilters = currentFilters
  },
  [SET_CURRENT_DATE_RANGE]: (state, currentDateRange) => {
    state.currentDateRange = currentDateRange
  },
  [SET_CURRENT_FORM_SUBMISSION]: (state, currentFormSubmission) => {
    state.currentFormSubmission = currentFormSubmission
  },
  [SET_CURRENT_LEAD]: (state, currentLead) => {
    state.currentLead = currentLead
  },
  [SET_SEARCH]: (state, search) => {
    state.search = search
  },
  [SET_DASHBOARD_DISPLAY_TYPE]: (state, type) => {
    state.dashboardDisplayType = type
  },
  [SET_CONVERSION_DASHBOARD]: (state, conversionDashboard) => {
    state.conversionDashboard = conversionDashboard
  },
  [SET_ACTION_DRAWER_OPENED]: (state, actionDrawerOpened) => {
    state.actionDrawerOpened = actionDrawerOpened
  },
  [SET_CURRENT_ACTION_DRAWER]: (state, currentActionDrawer) => {
    state.currentActionDrawer = currentActionDrawer
  },
  [SET_CURRENT_FORM_LEXICON]: (state, currentFormLexicon) => {
    state.currentFormLexicon = currentFormLexicon
  },
  [SET_CURRENT_LOCALE_TAB]: (state, currentLocaleTab) => {
    state.currentLocaleTab = currentLocaleTab
  },
  [SET_FORM_DEFAULT_TRANSLATIONS]: (state, defaultTranslations) => {
    state.defaultTranslations = defaultTranslations
  },
  [SET_FORM_MANDATORY_TRANSLATIONS]: (state, mandatoryTranslations) => {
    state.mandatoryTranslations = mandatoryTranslations
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getFormsList({ commit, rootState }) {
    const formsList = await getLeadsFormsApi(
      rootState.backoffice.currentClient.identifier,
      getShortLocal(rootState.backoffice.currentUser.locale)
    )
    commit(GET_LEADS_FORMS, formsList)
  },

  async getLeadsList({ commit, dispatch, rootState, state }, { formId, tableParams }) {
    if (state.currentForm?.id !== formId) {
      await dispatch('setCurrentForm', formId)
    }

    const urlParams = tableOptions(tableParams, 'leads')
    const formattedFilter = getFiltersParams(state.currentFilters)
    const filterParams = filterOptions({ ...formattedFilter, search: state.search }, 'leads')

    const leadsList = await getFormLeadsApi(
      rootState.backoffice.currentClient.identifier,
      formId,
      urlParams,
      filterParams
    )
    const formattedLeads = {
      ...leadsList,
      rows: flattenLeadsList(leadsList.rows),
      headers: formatLeadsHeaders(leadsList.headers),
    }

    commit(GET_FORM_LEADS, formattedLeads)
  },

  async getConditionableFields({ commit, rootState }, formId) {
    const conditionableFields = await getConditionableFieldsApi(
      rootState.backoffice.currentClient.identifier,
      getShortLocal(rootState.backoffice.currentUser.locale),
      formId
    )
    commit(GET_CONDITIONABLE_FIELDS, conditionableFields.rows)
  },

  async getDynamicTranslations({ commit, rootState, state }, formId) {
    const dynamicTranslations = await getDynamicTranslationsApi(
      formId,
      getShortLocal(rootState.backoffice.currentUser.locale)
    )
    const dynamicTranslationsObject = dynamicTranslations.reduce((dynamicTranslationAcc, dynamicTranslation) => {
      const translationsLocales = {}

      ;(state.currentForm?.translations?.activatedLocales || []).forEach(lang => {
        translationsLocales[lang] = ''
      })

      dynamicTranslationAcc[dynamicTranslation] = translationsLocales
      return dynamicTranslationAcc
    }, {})

    commit(GET_DYNAMIC_TRANSLATIONS, dynamicTranslationsObject)
  },

  async getLeadExport({ dispatch, rootState, state }, { formId, tableParams }) {
    dispatch('backoffice/setWorking', true, { root: true })

    const urlParams = tableOptions(tableParams, 'leads')
    const formattedFilter = getFiltersParams(state.currentFilters)
    const filterParams = filterOptions({ ...formattedFilter, search: state.search }, 'leads')
    try {
      const result = await getLeadExportApi(
        rootState.backoffice.currentClient.identifier,
        formId,
        urlParams,
        filterParams
      )
      const filename = getFileName(result.headers)
      downloadFile(result.data, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    } catch {
      dispatch(
        'backoffice/setAlert',
        {
          color: 'error',
          text: i18n.t('error.notification.default'),
        },
        { root: true }
      )
    }
    dispatch('backoffice/setWorking', false, { root: true })
  },

  async getConversionDashboard({ commit }) {
    const { id, clientId } = state.currentForm
    const dashboard = await getConversionDashboardApi({ formId: id, clientId, filters: state.currentFilters })
    commit('SET_CONVERSION_DASHBOARD', dashboard)
  },

  async getLPELocations({ commit, rootState }, query) {
    const lpeLocations = await getLPELocationsApi(rootState.backoffice.currentClient.uuid, query)
    const formattedLocations = lpeLocations.rows.map(location => ({
      ...location,
      label: location.company ? `${location.name} (${location.company})` : location.name,
    }))
    commit('GET_LPE_LOCATIONS', formattedLocations)
  },

  async getAdministrators({ commit, rootState }, locationId) {
    const administrators = await getAdministrators(rootState.backoffice.currentClient.uuid, locationId)
    commit('GET_ADMINISTRATORS', administrators)
  },

  clearLPELocations({ commit }) {
    commit('GET_LPE_LOCATIONS', [])
  },

  setCurrentFilters({ commit }, currentFilters) {
    commit(
      SET_CURRENT_FILTERS,
      currentFilters.filter(filter => filter.value)
    )
  },

  async setCurrentFormSubmission({ commit, rootState }, { formId, leadId }) {
    const currentFormSubmission = await getFormSubmissionApi(
      rootState.backoffice.currentClient.identifier,
      formId,
      leadId
    )
    const formattedFormSubmission = flattenLead(currentFormSubmission)
    commit(SET_CURRENT_FORM_SUBMISSION, formattedFormSubmission)
  },

  async setCurrentLead({ commit, rootState }, { formId, leadId }) {
    const currentLead = await getLeadApi(rootState.backoffice.currentClient.identifier, formId, leadId)
    const formattedLead = flattenLead(currentLead)
    commit(SET_CURRENT_LEAD, formattedLead)
  },

  setCurrentDateRange({ commit }, currentDateRange) {
    commit(SET_CURRENT_DATE_RANGE, currentDateRange)
  },

  setCurrentLocaleTab({ commit }, currentLocaleTab) {
    commit(SET_CURRENT_LOCALE_TAB, currentLocaleTab)
  },

  setSearch({ commit }, search) {
    commit(SET_SEARCH, search)
  },

  async editLead({ rootState }, { formId, leadId, leadContent }) {
    const formattedLeadContent = {
      fieldsValues: leadContent.fieldsValues,
      tags: leadContent.tags,
      internalNote: leadContent.internalNote,
    }

    if (leadContent.skus) {
      formattedLeadContent.skus = leadContent.skus
    }

    if (leadContent.price) {
      formattedLeadContent.price = leadContent.price.value
    }

    await putLeadEditApi(rootState.backoffice.currentClient.identifier, formId, leadId, formattedLeadContent)
  },

  //status
  async changeLeadStatus(_, url) {
    await putLeadStatusApi(url)
  },

  // deletion
  async deleteLeadWithUrl(_, url) {
    await deleteLeadApi(url)
  },

  //owner
  async changeLeadOwner(_, { url, notify, email }) {
    await putLeadOwnerApi(url, notify, email)
  },

  //location
  async changeLeadLocation(_, { url, locationId }) {
    await putLeadLocationApi(url, locationId)
  },

  //filters
  async setLeadsFilters({ commit, rootState }, formId) {
    const filtersList = await getLeadsFiltersApi(rootState.backoffice.currentClient.identifier, formId)
    commit(SET_LEADS_GENERIC_FILTER, filtersList.filters)
    commit(SET_LEADS_FIELD_FILTERS, filtersList.dynamicFilters)
  },

  //translations
  async setFormDefaultTranslations({ commit, rootState, dispatch }) {
    const defaultTranslations = await getFormDefaultTranslations(rootState.backoffice.currentClient.identifier)
    commit(
      SET_FORM_DEFAULT_TRANSLATIONS,
      toDotTranslationsObject(
        defaultTranslationsToList(defaultTranslations),
        defaultLangList.map(lang => lang.locale)
      )
    )
    dispatch('setFormMandatoryTranslations', getMandatoryTranslationKeys(defaultTranslations))
  },

  setFormMandatoryTranslations({ commit }, mandatoryTranslations) {
    commit(SET_FORM_MANDATORY_TRANSLATIONS, mandatoryTranslations)
  },

  //forms
  async setCurrentForm({ commit, rootState, dispatch }, formId) {
    let [currentForm] = await Promise.all([
      getFormApi(rootState.backoffice.currentClient.identifier, formId),
      dispatch('getConditionableFields', formId),
      dispatch('setLeadsFilters', formId),
    ])

    if (currentForm.settings.defaultLeadPrice) currentForm.settings.defaultLeadPrice /= 100

    const translationsDbo = {
      activatedLocales: currentForm.translations.activatedLocales,
      list: toDotTranslationsObject(currentForm.translations.list, currentForm.translations.activatedLocales),
    }

    commit(SET_CURRENT_FORM, {
      ...currentForm,
      translations: translationsDbo,
    })

    const userLocale = getShortLocal(rootState.backoffice.currentUser.locale)
    dispatch('setCurrentFormLexicon', {
      ...getPropertyByLocale(defaultLexicon, userLocale),
      ...getPropertyByLocale(currentForm.settings.lexicon, userLocale),
    })

    // Get fields and statics
    dispatch('getCurrentFormsFields')
    dispatch('getCurrentFormsStatics')
  },
  async getCurrentFormsFields({ commit, state, rootState }) {
    const formsFields = await getFormFieldsApi(rootState.backoffice.currentClient.identifier, state.currentForm.id)
    commit(GET_CURRENT_FORMS_FIELDS, formsFields)
  },
  async getCurrentFormsStatics({ commit, state, rootState }) {
    const formsStatics = await getFormStaticsApi(rootState.backoffice.currentClient.identifier, state.currentForm.id)
    commit(GET_CURRENT_FORMS_STATICS, formsStatics)
  },

  setDashboardDisplayType({ commit }, type) {
    commit(SET_DASHBOARD_DISPLAY_TYPE, type)
  },

  setActionDrawerOpened({ commit }, opened) {
    commit(SET_ACTION_DRAWER_OPENED, opened)
  },

  setCurrentActionDrawer({ commit }, currentActionDrawer) {
    commit(SET_CURRENT_ACTION_DRAWER, currentActionDrawer)
  },

  async updateCurrentFormSettings({ commit, rootState }, { formId, settings }) {
    const formattedSettings = { ...settings }
    if (formattedSettings.defaultLeadPrice) {
      formattedSettings.defaultLeadPrice *= 100
    }

    await putFormSettingsApi(rootState.backoffice.currentClient.identifier, formId, formattedSettings)

    if (formattedSettings.defaultLeadPrice) {
      formattedSettings.defaultLeadPrice /= 100
    }
    commit(SET_CURRENT_FORM_SETTINGS, settings)
  },
  async updateCurrentFormsFields({ commit, rootState }, { formId, fields }) {
    await setFormFieldsApi(rootState.backoffice.currentClient.identifier, formId, fields)
    commit(SET_CURRENT_FORM_FIELDS, fields)
  },
  async updateCurrentFormsStatics({ commit, rootState }, { formId, statics }) {
    await setFormStaticsApi(rootState.backoffice.currentClient.identifier, formId, statics)
    commit(SET_CURRENT_FORM_STATICS, statics)
  },

  async updateCurrentFormLocationSettings({ commit, rootState }, { formId, locationSettings }) {
    await putFormLocationSettingsApi(rootState.backoffice.currentClient.identifier, formId, locationSettings)
    commit(SET_CURRENT_FORM_LOCATION_SETTINGS, locationSettings)
  },

  async updateCurrentFormTranslations({ commit, rootState }, { formId, translations }) {
    const translationsDto = {
      activatedLocales: translations.activatedLocales,
      list: toObjectTranslations(translations.list),
    }

    await putFormTranslationsApi(rootState.backoffice.currentClient.identifier, formId, translationsDto)
    commit(SET_CURRENT_FORM_TRANSLATIONS, translations)
  },

  async updateCurrentFormNotifications({ commit, rootState }, { formId, notifications }) {
    await putFormNotificationsApi(rootState.backoffice.currentClient.identifier, formId, {
      ...notifications,
      layout: { ...notifications.layout, logo: notifications.layout.logo.id },
    })
    commit(SET_CURRENT_FORM_NOTIFICATIONS, notifications)
  },

  setCurrentFormLexicon({ commit }, lexicon) {
    commit(SET_CURRENT_FORM_LEXICON, lexicon)
  },

  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

// Tools
export const getFiltersParams = (searchFilters = []) => {
  return searchFilters.reduce((filterParams, filter) => {
    if (filter.type === 'fieldValue') {
      filterParams.fields = {
        ...(filterParams.fields || {}),
        [filter.slug]: encodeURIComponent(filter.value),
      }
      return filterParams
    }

    filterParams[filter.slug] = encodeURIComponent(filter.value)
    return filterParams
  }, {})
}

function formatLeadsHeaders(headers) {
  const formattedHeaders = formatLeadsStaticHeaders(headers.static)

  // Add display option for dynamic headers
  formattedHeaders.push(
    ...headers.dynamic.map(dynamicHeader => {
      const options = { ...dynamicHeader.options }

      switch (dynamicHeader.type) {
        case 'datepicker':
          options.format = 'L'
          break
      }

      return {
        text: dynamicHeader.name,
        value: dynamicHeader.slug,
        type: dynamicHeader.type,
        options: options,
      }
    })
  )

  // Add status and actions
  formattedHeaders.push(
    {
      text: i18n.t('leads.list.table.headers.status'),
      value: 'status',
      type: 'status',
    },
    {
      text: '',
      value: 'actions',
      type: 'actions',
    }
  )

  return formattedHeaders
}

function formatLeadsStaticHeaders(staticHeaders) {
  const formattedHeaders = []

  if (staticHeaders.includes('createdAt')) {
    formattedHeaders.push({
      text: i18n.t('leads.list.table.headers.createdAt'),
      value: 'createdAt',
      type: 'date',
      options: {
        format: 'L LT',
      },
    })
  }
  if (staticHeaders.includes('owner')) {
    formattedHeaders.push({
      text: i18n.t('leads.list.table.headers.owner'),
      value: 'owner',
      type: 'owner',
    })
  }
  if (staticHeaders.includes('tags')) {
    formattedHeaders.push({
      text: capitalize(state.currentFormLexicon.tags),
      value: 'tags',
      type: 'ChipList',
    })
  }
  if (staticHeaders.includes('price')) {
    formattedHeaders.push({
      text: i18n.t('leads.list.table.headers.price'),
      value: 'price',
      type: 'price',
    })
  }
  if (staticHeaders.includes('locationName')) {
    formattedHeaders.push({
      text: capitalize(state.currentFormLexicon.location),
      value: 'locationName',
      type: 'location',
    })
  }
  if (staticHeaders.includes('company')) {
    formattedHeaders.push({
      text: capitalize(state.currentFormLexicon.company),
      value: 'company',
      type: 'text',
    })
  }
  if (staticHeaders.includes('skus')) {
    formattedHeaders.push({
      text: capitalize(state.currentFormLexicon.skus),
      value: 'skus',
      type: 'skus',
    })
  }
  if (staticHeaders.includes('identity')) {
    formattedHeaders.push({
      text: capitalize(state.currentFormLexicon.identity),
      value: 'identity',
      type: 'identity',
    })
  }

  return formattedHeaders
}

function flattenLeadsList(leadsList) {
  return leadsList.map(lead => {
    const keyValues = {}
    lead.fieldsValues.forEach(keyValue => {
      if (keyValue.type === 'file') {
        keyValues[keyValue.slug] = keyValue.file
      } else {
        keyValues[keyValue.slug] = keyValue.value
      }
    })
    return {
      ...keyValues,
      createdAt: lead.createdAt,
      price: lead.price,
      locationName: lead.location?.name,
      company: lead.location?.company,
      address: lead.location?.address,
      skus: lead.skus,
      tags: lead.tags,
      status: lead.status,
      actions: lead.actions,
      newLeadSubscribers: lead.newLeadSubscribers,
      links: lead.links,
      id: lead.id,
      owner: lead.owner,
      identity: lead.identity,
    }
  })
}

function flattenLead(lead) {
  const fieldValuesFormatted = lead.fieldsValues.map(fieldValue => {
    const formattedFieldValue = {
      name: fieldValue.name,
      slug: fieldValue.slug,
      type: fieldValue.type,
      options: fieldValue.options,
      multiple: fieldValue.multiple,
    }

    if (fieldValue.value !== undefined) {
      formattedFieldValue.value = fieldValue.value

      if (fieldValue.type === 'file') {
        formattedFieldValue.value = { id: fieldValue.file.id, url: fieldValue.file.url }
      }
    }

    return formattedFieldValue
  })

  return {
    id: lead.id,
    createdAt: lead.createdAt,
    locationName: lead.location?.name,
    company: lead.location?.company,
    address: lead.location?.address,
    skus: lead.skus,
    tags: lead.tags,
    internalNote: lead.internalNote,
    status: lead.status,
    actions: lead.actions,
    links: lead.links,
    history: lead.history,
    additionalData: lead.additionalData,
    metadata: lead.metadata,
    owner: lead.owner,
    identity: lead.identity,
    price: lead.price,
    fieldsValues: fieldValuesFormatted,
  }
}

function toObjectTranslations(translations) {
  const translationsObject = {}

  for (const [translationKey, translationValue] of Object.entries(translations)) {
    if (translationKey.includes('.')) {
      const translationsKey = translationKey.split('.')

      let currentTranslations = translationsObject

      for (const subTranslationKey of translationsKey) {
        if (!currentTranslations[subTranslationKey]) {
          currentTranslations[subTranslationKey] = {}
        }
        currentTranslations = currentTranslations[subTranslationKey]
      }
      currentTranslations = Object.assign(currentTranslations, translationValue)
    } else {
      translationsObject[translationKey] = translationValue
    }
  }

  return translationsObject
}

function toDotTranslationsObject(translations, locales, dottedTranslations = {}, currentTranslation = '') {
  for (const translationKey in translations) {
    const translationValue = translations[translationKey]

    const newTranslationKey = currentTranslation ? `${currentTranslation}.${translationKey}` : translationKey

    if (translationValue && typeof translationValue === 'object' && !isI18nOject(translationValue, locales)) {
      toDotTranslationsObject(translationValue, locales, dottedTranslations, newTranslationKey)
    } else {
      dottedTranslations[newTranslationKey] = translationValue
    }
  }

  return dottedTranslations
}

function isI18nOject(value, locales) {
  return Object.keys(value).some(key => {
    return typeof key === 'string' && locales.includes(key)
  })
}

function defaultTranslationsToList(defaultTranslations) {
  if (defaultTranslations.values) {
    return defaultTranslations.values
  }
  const transformed = {}
  for (const [key, value] of Object.entries(defaultTranslations)) {
    transformed[key] = defaultTranslationsToList(value)
  }
  return transformed
}

function getMandatoryTranslationKeys(defaultTranslations) {
  const keys = []

  for (const [key, value] of Object.entries(
    toDotTranslationsObject(
      defaultTranslations,
      defaultLangList.map(lang => lang.locale)
    )
  )) {
    if (key.includes('.required') && value) {
      keys.push(key.replace('.required', ''))
    }
  }

  return keys
}
