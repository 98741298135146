import { URLS } from '@/config/urls.config'
import { sleep } from '@/utils/utilities.util'
import Cookies from 'js-cookie'

export const getUserToken = () => Cookies.get('userId', { domain: '.gtrsuite.com' })

export const isAuthorized = (to, appState, userToken) =>
  !to.meta.requiresAuth ||
  (appState.isLogged && isNavigationAccess(to.meta.roles, appState.currentUser) && userToken !== null)

export const isNavigationAccess = (allowedUsers, currentUser) => {
  return allowedUsers ? allowedUsers.includes(currentUser.type) : true
}

export const isLogged = (to, next, appState) => {
  if (to.name === 'SignIn' && appState.isLogged) {
    return next({ name: 'Home' })
  }
}

export const isInternalLink = item => {
  return item.gtrbo && item.url
}

export const isExternalLink = item => {
  return !item.gtrbo && item.url
}

export const redirectToExternalModule = async (module, clientId, openNewTab = false) => {
  const windowFunction = params =>
    openNewTab === true ? window.open(params, '_blank') : window.location.replace(params)

  if (module.product === 'gtrsuite') {
    windowFunction(`${URLS.BO_V2}/clients/${clientId}?module=${module.module}`)
    await sleep(2000)
  }
  if (module.product === 'gtrsuite_v1') {
    windowFunction(`${URLS.BO_V1}/bo/main/index.php`)
    await sleep(2000)
  }
  if (module.product === 'superadmin') {
    windowFunction(`${URLS.SUPER_ADMIN}`)
    await sleep(2000)
  }
}

export const isInternalModule = async (module, openNewTab) => {
  if (openNewTab) window.open(`${URLS.BO_V3}?module=${module.module}`, '_blank')
}
