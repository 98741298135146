import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_AUTH, GTR_CORE } from '@/config/api.config'

export const getAuthApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.AUTH}`)
}

export const postUpdatePasswordApi = payload => {
  return sendPostRequest(`${API_AUTH.CHANGE_PASSWORD}`, payload)
}

export const postSessionRefreshApi = () => {
  return sendPostRequest(
    `${API_AUTH.SESSION_REFRESH}`,
    {},
    {
      headers: {
        rid: 'session',
      },
    }
  )
}

export const postSendInvitationApi = ({ email, token }) => {
  return sendPostRequest(`${API_AUTH.INVITE_USER}`, {
    username: email,
    'g-recaptcha-response': token,
    invitation: true,
  })
}
