import Vue from 'vue'
import VueMarkdownEditor from '@kangc/v-md-editor'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

hljs.registerLanguage('json', json)
VueMarkdownEditor.use(vuepressTheme, { Hljs: hljs })

Vue.use(VueMarkdownEditor)

export default VueMarkdownEditor
