import Vue from 'vue'
import Vuex from 'vuex'
import backoffice from './modules/backoffice.store'
import profilehub from './modules/profilehub.store'
import autoreply from './modules/autoreply.store'
import touchpoints from './modules/touchpoints.store'
import users from './modules/users.store'
import leads from './modules/leads.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    backoffice,
    profilehub,
    autoreply,
    touchpoints,
    users,
    leads,
  },
  strict: process.env.NODE_ENV !== 'production',
})
