export const DashboardMode = {
  VOLUME: 'byVolume',
  VALUE: 'byValue',
}

export const LeadStatusColors = {
  new: '#a678e4',
  accepted: '#2A9DF4',
  converted: '#53B027',
  lost: '#ff5252',
}

export const LeadStatus = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  CONVERTED: 'converted',
  LOST: 'lost',
}

export const LeadAction = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  CONVERT: 'convert',
  LOSE: 'lose',
}
